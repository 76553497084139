import {
  type ReactNode,
  type RefObject,
  useCallback,
  useState,
  useMemo,
  type MutableRefObject,
} from 'react';

import { useDynamicWidgets } from 'react-instantsearch-core';

import { useRouter } from 'next/compat/router';

import { cn } from '@virginexperiencedays/tailwind';

import {
  categoriesHierarchyAttributeArray,
  categorySeparator,
  categoryTypes,
  featuresHierarchyAttributeArray,
  locationsHierarchyAttributeArray,
  MAX_VALUES_PER_FACET,
  occasionsHierarchyAttributeArray,
  refinementList,
} from '@virginexperiencedays/search/constants';
import { slugToName } from '@virginexperiencedays/search/utils/category';

import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { OptionBadge } from '@virginexperiencedays/components-v2/src/layout/Options';
import { DisplayHeading } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';
import { Button } from '@virginexperiencedays/components-v2/src/layout/Button';
import { IconMapPinExperience } from '@virginexperiencedays/components-v2/src/icons/IconMapPinExperience';

import { useStickyElement, useStickyFixed, useTouchMove } from '@virginexperiencedays/hooks';

import type { Override } from '@virginexperiencedays/categories/src/types';

import {
  filter as track,
  FilterInteraction,
  FilterSource,
} from '../../../../libs/tracking/filters';
import { mapAttributeToFilterType } from '../../../../libs/tracking/mapAttributeToFilterType';
import { mapPathnameToPageType } from '../../../../libs/tracking/mapPathnameToPageType';

import type { AlgoliaAttribute } from '../../../../libs/algolia/stateToRoute';
import { usePagination } from '../../../../libs/algolia/hooks/usePagination';
import { useCurrentRefinements } from '../../../../libs/algolia/hooks/useCurrentRefinements';
import { getAttributeLvl } from '../../../../libs/algolia/getAttributeLvl';
import { isDesktopFilter } from '../../../../libs/algolia/isDesktopFilter';
import { getCurrentCategoryAlgoliaAttribute } from '../../../../libs/algolia/getCurrentCategoryAlgoliaAttribute';

import { SortWidget, SortWidgetDesktop } from './Widgets/SortWidget';
import { FilterContent } from './Filter/FilterContent';
import { Filter } from './Filter';
import { AllFiltersContent } from './AllFilters/AllFiltersContent';
import { AllFilters } from './AllFilters';

export type QuickFilterBarProps = {
  containerRef?: RefObject<HTMLDivElement>;
  className?: string;
  title?: string;
  overridesSlugInfo: Override['slugInfo'];
  categoryType: categoryTypes;
  serverSlug: string[];
  refElementToScroll: MutableRefObject<HTMLElement>;
};

type FilterContentItem = {
  onReset: () => void;
  value: string;
  text: string;
  activeText?: string;
  activeCount: number;
  optionsAction?: {
    text: string;
    onClick: () => void;
  };
  hasArrow?: boolean;
  icon: ReactNode;
  widgetCategoryType: categoryTypes;
};

export const QuickFilterBar = ({
  title = 'All Experiences',
  overridesSlugInfo,
  categoryType,
  serverSlug,
  refElementToScroll,
}: QuickFilterBarProps) => {
  const router = useRouter();
  const pathname = router?.pathname;
  const currentLvl = serverSlug.length - 1;

  const hasGeoSearchQuery = !!router?.query?.geoSearch;
  const hasLocationQuery = !!router?.query?.location;
  const hasGeoSearchText = !!router?.query?.geoSearchFormatted;
  const normalizedGeoSearchFormatted = Array.isArray(router?.query?.geoSearchFormatted)
    ? router?.query?.geoSearchFormatted.join(',')
    : router?.query?.geoSearchFormatted;

  // Layout hooks
  const [stickyRef, isStickyMobile] = useStickyFixed<HTMLDivElement>({ mobileOnly: true, placement: 'top' });
  const { isSticky } = useStickyElement({ stickyRef });
  const { scrolling, handleScroll, handleTouchStart, handleTouchEnd } = useTouchMove({
    onTouchEnd: ({ direction }) => {
      track({
        title: 'Quick Filter Bar',
        pageType: mapPathnameToPageType(pathname),
        position: 0,
        source: FilterSource.QuickFilterBar,
        interaction:
          direction === 'previous' ? FilterInteraction.SwipeBack : FilterInteraction.SwipeToMore,
        qfTotalProductCount: nbOfHits,
      });
    },
  });

  const regionAttribute = locationsHierarchyAttributeArray[
    getAttributeLvl(categoryType, categoryTypes.LOCATION, currentLvl, overridesSlugInfo)
  ] as AlgoliaAttribute;
  const categoryAttribute = categoriesHierarchyAttributeArray[
    getAttributeLvl(categoryType, categoryTypes.CATEGORY, currentLvl, overridesSlugInfo)
  ] as AlgoliaAttribute;
  const occasionAttribute = occasionsHierarchyAttributeArray[
    getAttributeLvl(categoryType, categoryTypes.OCCASION, currentLvl, overridesSlugInfo)
  ] as AlgoliaAttribute;
  const featureAttribute = featuresHierarchyAttributeArray[
    getAttributeLvl(categoryType, categoryTypes.FEATURE, currentLvl, overridesSlugInfo)
  ] as AlgoliaAttribute;

  const dynamicValidAttributes = useMemo(
    () => [
      regionAttribute,
      categoryAttribute,
      occasionAttribute,
      featureAttribute,
      refinementList.PRICE_RANGE,
      refinementList.RATING,
    ],
    [
      regionAttribute,
      categoryAttribute,
      occasionAttribute,
      featureAttribute,
      serverSlug,
      overridesSlugInfo,
    ]
  );

  // Algolia hooks
  const currentCategoryAttributes =
    getCurrentCategoryAlgoliaAttribute(categoryType, serverSlug, overridesSlugInfo) || null;
  const { nbHits: nbOfHits } = usePagination();
  const countLabel = `${nbOfHits} Products`;

  const { items: currentRefinements, refine: clearRefinement } = useCurrentRefinements({
    transformItems: useCallback((items) => {
      // - Remove the current category in CLP/PLP based from server slug and refinement value
      // - Ensure category has refinements
      // - Remove region refinements with empty value (means using geo search) or if passed in url query
      // - Remove seo overrides refinements
      const newItems = items
        .map(({ refinements, ...rest }) => ({
          ...rest,
          refinements: refinements.filter(({ attribute, value }) => {
            const isSeoOverrideRefinement = overridesSlugInfo?.some((override) =>
              override.hierarchy.join('/').startsWith(String(value))
            );
            const current = String(value).split('/');
            const isFacetCurrentSlug = serverSlug.includes(current[current.length - 1]);
            const isFilteringGeoSearch =
              (locationsHierarchyAttributeArray.includes(attribute) && value === '') ||
              hasGeoSearchQuery;
            return !isFacetCurrentSlug && !isFilteringGeoSearch && !isSeoOverrideRefinement;
          }),
        }))
        .filter(({ refinements }) => refinements?.length > 0);

      return newItems;
    }, []),
  });

  const { attributesToRender: dynamicAttributes } = useDynamicWidgets({
    maxValuesPerFacet: MAX_VALUES_PER_FACET,
  });

  // DO NOT USE transform items for attributes to render (it requires to use useCallback)
  // when using soft navigation useCallback gets stale and there's a mismatch between dynamic attributes and attributes to render due to this
  // always allow to render location attribute that starts with facet.regionHierarchies as the UI has nested geoSearch
  const attributesToRender = dynamicAttributes.filter((attribute: AlgoliaAttribute) => {
    if (
      attribute.startsWith('facets.regionHierarchies') &&
      dynamicValidAttributes.includes(attribute)
    ) {
      return true;
    }

    return (
      dynamicValidAttributes.includes(attribute) && !currentCategoryAttributes?.includes(attribute)
    );
  });

  // We need to memoize initial render cause algolia has a bug
  // that when facet ordering is applied via rules, and geoSearch is used, for some reason it ignores rules?
  // And just fallbacks to use original order defined in facet display
  // meaning that there is no flash to the user, however
  // if user landed on a CLP and had different facet display order than default
  // then after applying geoSearch and refreshing the page (ending up on PLP) he'll see different order

  const createLocationWidgetLabel = () => {
    if (hasGeoSearchText) {
      return normalizedGeoSearchFormatted;
    }

    const location = router?.query?.location;

    if (router?.isReady && typeof location === 'string' && location.split(',').length === 1) {
      return slugToName(location.split(categorySeparator).pop());
    }

    return 'Location';
  };

  const filterContent: Partial<Record<AlgoliaAttribute, FilterContentItem>> = useMemo(
    () => ({
      // for geosearch and region use router query instead, algolia current refinements just returns empty 'regionHierarchies' array when using _geoloc
      [regionAttribute]: {
        onReset: () =>
          clearRefinement({ attribute: hasGeoSearchQuery ? 'geoSearch' : regionAttribute }),
        value: 'location',
        text: 'Location',
        activeText: createLocationWidgetLabel(),
        activeCount: hasLocationQuery
          ? currentRefinements.find(({ attribute }) => attribute === regionAttribute)?.refinements
              ?.length
          : hasGeoSearchQuery
          ? 1
          : 0,
        optionsAction: {
          text: 'Reset',
          onClick: () =>
            clearRefinement({ attribute: hasGeoSearchQuery ? 'geoSearch' : regionAttribute }),
        },
        icon: (
          <IconMapPinExperience
            className={cn(
              'text-neutral dark:md:group-hover/pill:text-persistent-text-dark',
              (hasLocationQuery || hasGeoSearchQuery) && 'dark:text-persistent-text-dark-strong'
            )}
          />
        ),
        widgetCategoryType: categoryTypes.LOCATION,
      },
      [categoryAttribute]: {
        onReset: () => clearRefinement({ attribute: categoryAttribute }),
        value: 'category',
        text: 'Category',
        activeCount:
          currentRefinements.find(({ attribute }) => attribute === categoryAttribute)?.refinements
            ?.length || 0,
        hasArrow: true,
        optionsAction: {
          text: 'Reset',
          onClick: () => clearRefinement({ attribute: categoryAttribute }),
        },
        icon: null,
        widgetCategoryType: categoryTypes.CATEGORY,
      },
      [refinementList.PRICE_RANGE]: {
        onReset: () => clearRefinement({ attribute: 'facets.priceRange' }),
        activeCount:
          currentRefinements.find(({ attribute }) => attribute === 'facets.priceRange')?.refinements
            ?.length || 0,

        value: 'price',
        text: 'Price',
        hasArrow: true,
        optionsAction: {
          text: 'Reset',
          onClick: () => {
            clearRefinement({ attribute: 'facets.priceRange' });
          },
        },
        icon: null,
        widgetCategoryType: categoryTypes.PRICE_RANGE,
      },
      [refinementList.RATING]: {
        onReset: () => clearRefinement({ attribute: 'facets.reviewRanges' }),
        value: 'rating',
        text: 'Rating',
        activeCount:
          currentRefinements.find(({ attribute }) => attribute === 'facets.reviewRanges')
            ?.refinements?.length || 0,

        optionsAction: {
          text: 'Reset',
          onClick: () => {
            clearRefinement({ attribute: 'facets.reviewRanges' });
          },
        },
        icon: null,
        widgetCategoryType: categoryTypes.RATING,
        hasArrow: true,
      },
      [featureAttribute]: {
        onReset: () => clearRefinement({ attribute: featureAttribute }),
        value: 'feature',
        text: 'Feature',
        hasArrow: true,
        activeCount:
          currentRefinements.find(({ attribute }) => attribute === featureAttribute)?.refinements
            ?.length || 0,
        optionsAction: {
          text: 'Reset',
          onClick: () => clearRefinement({ attribute: featureAttribute }),
        },
        icon: null,
        widgetCategoryType: categoryTypes.FEATURE,
      },
      [occasionAttribute]: {
        onReset: () => clearRefinement({ attribute: occasionAttribute }),
        value: 'occasion',
        text: 'Occasion',
        hasArrow: true,
        activeCount:
          currentRefinements.find(({ attribute }) => attribute === occasionAttribute)?.refinements
            ?.length || 0,
        optionsAction: {
          text: 'Reset',
          onClick: () => clearRefinement({ attribute: occasionAttribute }),
        },
        icon: null,
        widgetCategoryType: categoryTypes.OCCASION,
      },
    }),
    [router?.query, currentRefinements]
  );

  // All Filters props
  const totalActiveCount = useMemo(
    () => Object.values(filterContent).reduce((acc, item) => acc + item.activeCount, 0),
    [filterContent]
  );
  const filters = useMemo(
    () =>
      Object.entries(filterContent).reduce((acc, [key, { text, activeCount }]) => {
        return {
          ...acc,
          [key]: { text, activeCount },
        };
      }, {}),
    [filterContent]
  );

  const [open, setOpen] = useState(
    attributesToRender.reduce(
      (acc, attribute) => {
        return { ...acc, [attribute]: false };
      },
      { all_filters: false } as Record<string, boolean>
    )
  );

  const handleScrollOnFacetClick = () => {
    const isMobile = !isDesktopFilter();
    const element = refElementToScroll.current;
    const mobileNavBarHeight = isMobile ? 60 : 0;

    if (!element) return;

    // scroll back to the top of the grid only when passed sticky bar
    if (window.scrollY > stickyRef.current?.offsetTop) {
      window.scrollTo({
        top: (element?.offsetTop || 0) - mobileNavBarHeight,
      });
    }
  };

  return (
    <>
      <Container>
        <DisplayHeading
          size="2"
          data-testid="quick-filter-title"
          className="flex flex-wrap items-baseline gap-2 text-[22px] leading-tight"
        >
          {title}
          {/* Desktop only */}
          {!!nbOfHits && (
            <OptionBadge
              data-testid="quick-filter-product-count"
              className="text-neutral-strong hidden bg-transparent font-normal uppercase leading-4 md:block"
            >
              {countLabel}
            </OptionBadge>
          )}
        </DisplayHeading>
      </Container>

      <div
        ref={stickyRef}
        id="quick-filter-filters"
        className={cn(
          'bg-background-page z-[12] pb-0 pt-4 md:py-4',
          // had to add top: -1px to prevent css position sticky causing 1px gap at the top
          'lg:sticky lg:-top-px lg:left-0 lg:right-0',
          (isStickyMobile || isSticky) &&
            'shadow-lg dark:shadow-[0_10px_15px_-3px_rgb(148_148_148_/_0.1),_0_4px_6px_-4px_rgb(148_148_148_/_0.1)]'
        )}
      >
        <Container>
          <div className="flex items-center justify-between">
            {/* Quick Filter */}
            <div
              className={cn(
                'hide-scrollbar flex snap-x snap-mandatory snap-start items-center gap-2',
                // mobile triggers drawer, so should scroll-x which also hides overflow for y
                // desktop triggers dropdown, so should not hide overflow
                'md:hover-only:overflow-visible overflow-x-auto',
                // max-width should give space on the menus at the right (sort, all filters)
                'md:max-w-[calc(100%_-_110px)] lg:max-w-[calc(100%_-_182px)]'
              )}
              onScroll={handleScroll}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <ul className="flex items-center gap-2 pr-4">
                {attributesToRender.map((attribute: AlgoliaAttribute, index) => {
                  const isLocationWidget = attribute === regionAttribute;
                  const item = filterContent[attribute];

                  // we ignore GEO_SEARCH as its now displaying as part of `facets.regionHierarchies`
                  if (attribute === 'geoSearch') return;

                  return (
                    <>
                      <Filter
                        overridesSlugInfo={overridesSlugInfo}
                        serverSlug={serverSlug}
                        categoryType={categoryType}
                        widgetCategoryType={categoryType}
                        attribute={attribute}
                        value={item.value}
                        text={item?.activeText || item.text}
                        icon={item.icon}
                        hasArrow={item.hasArrow}
                        activeCount={item.activeCount}
                        // tracking for mouse enter/leave events start when user actually hovers over the list, to prevent spamming
                        onMouseEnter={(attribute) => {
                          if (isDesktopFilter() && !isLocationWidget) {
                            setOpen((prev) => ({ ...prev, [attribute]: true }));
                          }
                        }}
                        onMouseLeave={(attribute) => {
                          if (isDesktopFilter() && !isLocationWidget) {
                            setOpen((prev) => ({ ...prev, [attribute]: false }));
                          }
                        }}
                        onClick={(attribute) => {
                          if (!isDesktopFilter() || isLocationWidget) {
                            setOpen((prev) => ({ ...prev, [attribute]: true }));

                            track({
                              title: item.text,
                              type: mapAttributeToFilterType(attribute),
                              pageType: mapPathnameToPageType(pathname),
                              position: index + 1,
                              source: isLocationWidget
                                ? FilterSource.LocationFilter
                                : FilterSource.FilterDrawer,
                              interaction: FilterInteraction.ClickFilter,
                              qfTotalProductCount: nbOfHits,
                            });
                          }
                        }}
                      >
                        {open[attribute] && (
                          <FilterContent
                            serverSlug={serverSlug}
                            categoryType={categoryType}
                            attribute={attribute}
                            text={item.text}
                            filterIndex={index}
                            onReset={item.onReset}
                            activeCount={item.activeCount}
                            optionsAction={item.optionsAction}
                            currentCategoryAttributes={currentCategoryAttributes}
                            overridesSlugInfo={overridesSlugInfo}
                            setOpen={(attribute, value) => {
                              setOpen((prev) => ({ ...prev, [attribute]: value }));
                            }}
                            filterType={isLocationWidget ? 'modal-drawer' : 'dropdown-drawer'}
                            open={open[attribute]}
                            dropdownClassName={
                              index >= 5 ? 'md:left-auto md:right-0 lg:left-0 lg:right-auto' : ''
                            }
                            onOptionClick={handleScrollOnFacetClick}
                          />
                        )}
                      </Filter>
                    </>
                  );
                })}
                {!!totalActiveCount && (
                  <li>
                    <Button
                      onClick={() => {
                        clearRefinement(null, true);

                        track({
                          title: 'Clear all filters',
                          pageType: mapPathnameToPageType(pathname),
                          position: attributesToRender.length + 1,
                          source: FilterSource.QuickFilterBar,
                          interaction: FilterInteraction.ClickReset,
                          qfTotalProductCount: nbOfHits,
                        });
                      }}
                      variant="invisible"
                      className="text-nowrap p-1 font-normal underline"
                    >
                      Clear all filters
                    </Button>
                  </li>
                )}
              </ul>
            </div>

            {/* All filters and sort */}
            <ul
              className={cn(
                'bg-background-page relative flex gap-2',
                // styles for the gradient overlay in quickfilter bar
                'after:absolute after:left-0 after:top-0 after:h-full after:w-[40px] after:-translate-x-full after:bg-[linear-gradient(to_right,_rgba(255,_255,_255,_0)_0%,_var(--background-page)_100%)] after:opacity-100 after:transition-opacity after:content-[""]',
                scrolling.isEnd && 'after:opacity-0'
              )}
            >
              <li>
                <AllFilters
                  activeCount={totalActiveCount}
                  onMouseEnter={() => null}
                  onMouseLeave={() => null}
                  onClick={(attribute) => {
                    setOpen((prev) => ({ ...prev, [attribute]: true }));

                    track({
                      title: 'All Filters',
                      type: null, // N/A
                      pageType: mapPathnameToPageType(pathname),
                      position: 1,
                      source: FilterSource.QuickFilterBar,
                      interaction: FilterInteraction.ClickAllFilters,
                      qfTotalProductCount: nbOfHits,
                    });
                  }}
                  iconClass={cn(
                    'text-neutral',
                    !!totalActiveCount && 'dark:text-persistent-text-dark-strong'
                  )}
                >
                  {open['all_filters'] && (
                    <AllFiltersContent
                      serverSlug={serverSlug}
                      categoryType={categoryType}
                      overridesSlugInfo={overridesSlugInfo}
                      text="All Filters"
                      attribute={'all_filters' as any}
                      validAttributes={dynamicValidAttributes}
                      open={open['all_filters']}
                      setOpen={(attribute, value) => {
                        setOpen((prev) => ({ ...prev, [attribute]: value }));
                      }}
                      filters={filters}
                      currentRefinements={currentRefinements as any}
                      totalActiveCount={totalActiveCount}
                      onRemoveCurrentRefinement={({ value, position, count }) => {
                        track({
                          title: 'All Filters',
                          type: null, // N/A
                          pageType: mapPathnameToPageType(pathname),
                          position: 1,
                          optionTitle: value,
                          optionPosition: position,
                          optionNumberOfProducts: count,
                          source: FilterSource.AllFilters,
                          interaction: FilterInteraction.DeselectAppliedFilter,
                          qfTotalProductCount: nbOfHits,
                        });
                      }}
                      onReset={(attribute) => {
                        clearRefinement(null, true);
                        setOpen((prev) => ({ ...prev, [attribute]: false }));
                        track({
                          title: 'All Filters',
                          type: null, // N/A
                          pageType: mapPathnameToPageType(pathname),
                          position: 1,
                          source: FilterSource.AllFilters,
                          interaction: FilterInteraction.ClickReset,
                          qfTotalProductCount: nbOfHits,
                        });
                      }}
                      onOptionClick={handleScrollOnFacetClick}
                      currentCategoryAttributes={currentCategoryAttributes}
                    />
                  )}
                </AllFilters>
              </li>
              {/* Sorting desktop */}
              <li className="hidden md:flex">
                <SortWidgetDesktop
                  className="px-3 xl:px-2"
                  onClick={({ value, position }) => {
                    track({
                      title: 'Sort',
                      type: null, // N/A
                      pageType: mapPathnameToPageType(pathname),
                      position: 1,
                      optionTitle: value,
                      optionPosition: position,
                      optionNumberOfProducts: null,
                      source: FilterSource.Sort,
                      interaction: FilterInteraction.SelectSortOption,
                      qfTotalProductCount: nbOfHits,
                    });

                    handleScrollOnFacetClick();
                  }}
                />
              </li>
            </ul>
          </div>

          {/* Mobile only */}
          {/* Have other menus on a new line */}
          <div className="flex items-center justify-between gap-2 md:hidden">
            <span className="text-xs uppercase leading-4" data-testid="quick-filter-product-count">
              {countLabel}
            </span>
            <SortWidget
              className="border-none p-0 hover:bg-transparent"
              onOpenClose={(value) => {
                track({
                  title: 'Sort',
                  type: null, // N/A
                  pageType: mapPathnameToPageType(pathname),
                  position: 1,
                  source: FilterSource.QuickFilterBar,
                  interaction: value ? FilterInteraction.ClickSort : FilterInteraction.SortClosed,
                  qfTotalProductCount: nbOfHits,
                });
              }}
              onClick={({ value, position }) => {
                track({
                  title: 'Sort',
                  type: null, // N/A
                  pageType: mapPathnameToPageType(pathname),
                  position: 1,
                  optionTitle: value,
                  optionPosition: position,
                  optionNumberOfProducts: null,
                  source: FilterSource.Sort,
                  interaction: FilterInteraction.SelectSortOption,
                  qfTotalProductCount: nbOfHits,
                });

                handleScrollOnFacetClick();
              }}
            />
          </div>
        </Container>
      </div>
    </>
  );
};
